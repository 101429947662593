<template>
  <v-container v-if="show" fluid>
    <v-row align="center" class="feature-banner font-weight-bold pa-3 primary text-white">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="html" v-html="html" />
      <div v-else>
        {{ text }}
        <span v-if="url"> -
          <a class="text-white" :href="url" target="_blank">
            LEARN MORE
          </a>
        </span>
      </div>
      <v-spacer />
      <v-btn class="mr-3" color="white" icon size="small" @click="closed = true">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'feature-banner',
  data() {
    return {
      closed: false
    }
  },
  computed: {
    html() {
      return this.$config.BANNER_FEATURE_HTML
    },
    show() {
      return !!(!this.closed && (this.text || this.html))
    },
    text() {
      return this.$config.BANNER_FEATURE_TEXT || undefined
    },
    url() {
      return this.$config.BANNER_FEATURE_URL || undefined
    },
  },
  mounted() {
    if (this.show) {
      window.addEventListener('scroll', () => {
        this.closed = true
      })
    }
  },
}
</script>

<style lang="scss">
.feature-banner > div > a {
  color: white;
}
</style>
