<template>
  <v-app class="logged-in-app-layout">
    <app-header>
      <template v-slot:append>
        <help-menu />
      </template>
    </app-header>
    <app-drawer class="app-drawer" />
    <v-main>
      <v-divider class="top-border" />
      <feature-banner />
      <slot />
    </v-main>
    <purchase-order-legal-notice />
    <order-action-dialog />
    <global-snackbar />
  </v-app>
</template>

<script>
import AppDrawer from '~/components/app-drawer'
import AppHeader from '~/components/app-header'
import FeatureBanner from '~/components/feature-banner'
import GlobalSnackbar from '~/components/global-snackbar'
import HelpMenu from '~/components/help-menu'
import PurchaseOrderLegalNotice from '~/components/purchase-order-legal-notice'
import OrderActionDialog from '~/components/admin/order-action-dialog'

export default {
  name: 'logged-in-app-layout',
  components: {
    AppDrawer,
    AppHeader,
    FeatureBanner,
    GlobalSnackbar,
    HelpMenu,
    PurchaseOrderLegalNotice,
    OrderActionDialog,
  },
}
</script>

<style lang="scss" scoped>
$top-border-z: 4;

.app-drawer {
  z-index: $top-border-z + 1 !important;
}

.top-border {
  position: fixed;
  width: 100%;
  z-index: $top-border-z;
}
</style>
