<template>
  <qtm-dialog-card v-model="showDialog" data-test="po-legal-notice" padding="9" title="Notice" width="325">
    <p class="text-dark-grey">
      The legal contract for the supply and purchase of goods and/or services is between the customer and the vendor
      that you place your order with. QuoteToMe has no control over the actions or omissions of any vendor or the
      quality of product provided.  If you experience problems with your order, contact the vendor directly.
    </p>
    <qtm-checkbox v-model="doNotShowAgain" class="mt-3" label="Do not show me this again" />
    <template v-slot:actions>
      <qtm-btn block class="my-2" data-test="ok-button" size="x-large" @click="acceptLegalNotice">
        Ok
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script>
export default {
  name: 'purchase-order-legal-notice',
  emits: ['legal-notice-shown'],
  data() {
    return {
      doNotShowAgain: false,
      showDialog: false,
      legalVersion: 0,
    }
  },
  mounted() {
    this.showLegalNotice()
  },
  methods: {
    acceptLegalNotice() {
      if (this.doNotShowAgain) {
        localStorage.setItem('poLegalVersion', this.legalVersion)
      }
      else {
        localStorage.removeItem('poLegalVersion')
      }
      this.showDialog = false
      this.$emit('legal-notice-shown')
    },

    showLegalNotice() {
      const legalVersion = parseInt(localStorage.getItem('poLegalVersion'), 10)

      if (Number.isNaN(legalVersion) || legalVersion < this.legalVersion) {
        this.showDialog = true
      }
      else {
        this.$emit('legal-notice-shown')
      }
    },
  }
}
</script>
